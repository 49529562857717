.introduction-wrapper {}

.introduction-container {
    height: 300px;
    width: 100%;
    background-image: linear-gradient(to bottom, rgba(38, 106, 143, 0.5), rgba(90, 45, 129, .9)), url("../sac_river2.jpg");
    background-position: 0% 35%;
    background-size: cover;
}

.introduction-text-container1 {
    width: 75%;
    color: white;
    padding-top: 5%;
    padding-left: 20%;
}

.introduction-text-container1 h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    font-weight: 900;
}

.introduction-text-container1 p {
    
    font-family: 'Roboto', sans-serif;
    padding-top: 12px;
    line-height: 30px;
    font-size: 20px;
    font-weight: 200;
}

.aboutus-container {
    position: center;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.aboutus-text p {
    
    font-family: 'Roboto', sans-serif;
    line-height: 40px;
    font-size: 22px;
}

.values-container {
    position: center;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.values-container h1 {
    
    font-family: 'Roboto', sans-serif;
}

.values-container li {
    
    font-family: 'Roboto', sans-serif;
    list-style: none;
}

.team-title {
    text-align: center;
    padding: 30px;
    font-size: 26px;
}

.executive-super-wrap {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.executive-wrapper {
    display: grid;
    grid-template-columns: 50% 50% 50%;
    padding: 20px;
}

.executive-container1 {
    background-color: white;
    padding: 5px;
    text-align: center;
}

.team_1 {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 25px;
    overflow: hidden;
}

.team_2 {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    padding: 25px;
    overflow: hidden;
}

.team_1 h2,
h3 {
    padding-left: 0px;
    font-size: 18px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.team_1 p {
    padding-left: 0px;
    text-align: left;
    padding-bottom: 25px;
    
    font-family: 'Roboto', sans-serif;
}

.team_2 h2,
h3 {
    padding-left: 0px;
    font-size: 18px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.team_2 p {
    padding-left: 0px;
    text-align: left;
    padding-bottom: 25px;
    
    font-family: 'Roboto', sans-serif;
}

.executive-container2 {
    background-color: white;
    padding: 5px;
    text-align: center;
}

.team_1 img {
    text-align: center;
    height: 400px;
    width: 400px;
    border-radius: 50%;
    object-fit: cover;
}

.team_2 img {
    text-align: center;
    height: 400px;
    width: 400px;
    border-radius: 50%;
    object-fit: cover;
}

.social-icon-link i {
    font-size: 40px;
    color: #0e76a8;
    margin-right: 95%;
}

.flagGradient {
    text-align: center;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    height: 7px;
    background: -webkit-linear-gradient(red, blue);
    /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(red, white, blue);
    /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(red, white, blue);
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(red, white, blue);
    /* Standard syntax (must be last) */
}

.enterprise-container {
    text-align: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    padding: 40px 0 40px 0;
}

.enterprise-container h1 {
    font-size: 20px;
    
    font-family: 'Roboto', sans-serif;
    margin-top: 50px;
}

.dvbe-container {
    margin-left: auto;
    margin-right: auto;
}

.dvbe-container img {
    height: 200px;
    width: 200px;
}

.sdvosb-container {
    margin-left: auto;
    margin-right: auto;
}

.sdvosb-container img {
    height: 200px;
    width: 200px;
}

@media only screen and (max-width: 1600px) {
    .enterprise-container h1 {
        font-size: 18px;
    }
    .team_1 img {
        height: 300px;
        width: 300px;
    }
    .team_2 img {
        height: 300px;
        width: 300px;
    }
}

@media only screen and (max-width: 1200px) {
    .intro-wrapper {
        display: inline;
        grid-template-columns: 100% 100% 100%;
    }
    .intro-values {
        padding: 100px;
        font-size: 40px;
        color: white;
    }
    .intro-values ul {
        font-size: 20px;
        padding: 15px;
    }
    .intro-container {
        padding: 0px;
    }
    .executive-wrapper {
        display: inline;
        grid-template-columns: 100% 100% 100%;
    }
    .enterprise-container {
        font-size: 16px;
        margin-left: auto;
        margin-right: auto;
        width: 70%;
        padding: 20px 0 20px 0;
        font-family: 'Roboto', sans-serif;
    }
    .enterprise-container h1 {
        font-size: 16px;
    }
    .flagGradient {
        width: 70%;
    }
    .aboutus-container {
        width: 70%;
    }
}

@media only screen and (max-width: 1000px) {
    .enterprise-container {
        font-size: 16px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        padding: 20px 0 20px 0;
        
        font-family: 'Roboto', sans-serif;
    }
    .flagGradient {
        width: 100%;
    }
}

 @media only screen and (max-width: 600px) {
    .introduction-text-container1 {
        width: 80%;
        color: white;
        padding-top: 8%;
        padding-left: 10%;
    }
    .enterprise-container h1 {
        font-size: 12px;
        margin-top: 25px;
    }
    .dvbe-container img {
        height: 100px;
        width: 100px;
    }
    .sdvosb-container img {
        height: 100px;
        width: 100px;
    }
    .team_1 img {
        height: 200px;
        width: 200px;
    }
    .team_2 img {
        height: 200px;
        width: 200px;
    }
} 