.partners-container {
    font-size: 44px;
    text-align: center;
    background-color: red;
    color: #000042;
    padding: 50px;
    z-index: 1;
}

.partners-container h2 {
    font-family: 'Roboto', sans-serif;
    color: darkblue;
    font-size: 40px;
    text-shadow: 1px 1px 2px #0F52BA;
}

.slick-track {
    display: flex !important;
    height: 400px;
}

.slick-slide {
    height: inherit !important;
}

.partners_logo1 {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    transform: scale(.3, .3);
    margin-top: 70px;
    margin-left: 0px;
}

.partners_logo2 {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    transform: scale(.2, .2);
    margin-top: -225px;
    margin-left: -100px;
}

.partners_logo3 {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    transform: scale(.75, .75);
    margin-top: 130px;
    margin-left: 50px;
}

.partners_logo4 {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    transform: scale(.35, .45);
    margin-top: 135px;
    margin-left: -50px;
}

.partners_logo5 {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    transform: scale(.32, .32);
    margin-top: 105px;
}

.partners-container {
    font-size: 44px;
    text-align: center;
    background-color: white;
    color: #000042;
    height: auto;
}

.partners-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.partners-link {
    color: #09d3ac;
}

.partners_slides .slick-prev,
.partners_slides .slick-next {
    position: absolute;
    top: 50%;
    z-index: 1;
}

.partners_slides {
    position: relative;
}

.partners_slides .slick-prev,
.partners_slides .slick-next {
    position: absolute;
    top: 50%;
    z-index: 1;
}

.partners_slides .slick-prev {
    left: 2%;
}

.partners_slides .slick-next {
    right: 2%;
}

.slick-prev:before {
    color: red !important;
    background-color: #eee;
}

.slick-next:before {
    color: red !important;
    background-color: #eee;
}

@media only screen and (max-width: 1400px) {
    .partners-container {
        font-size: 30px;
        text-align: center;
        padding: 50px;
    }
    .partners_logo1 {
        transform: scale(.3, .3);
        margin-top: 70px;
        margin-left: 75px;
    }
    .partners_logo2 {
        transform: scale(.2, .2);
        margin-top: -225px;
        margin-left: -60px;
    }
    .partners_logo3 {
        transform: scale(.75, .75);
        margin-top: 130px;
        margin-left: 40px;
    }
    .partners_logo4 {
        transform: scale(.35, .45);
        margin-top: 135px;
        margin-left: -30px;
    }
    .partners_logo5 {
        transform: scale(.32, .32);
        margin-top: 105px;
        margin-left: 50px;
    }
}

@media only screen and (max-width: 1000px) {
    .partners_logo1 {
        transform: scale(.20, .2);
        margin-top: 70px;
        margin-left: 0px;
    }
    .partners_logo2 {
        transform: scale(.12, .12);
        margin-top: -225px;
        margin-left: -40px;
    }
    .partners_logo3 {
        transform: scale(.4, .4);
        margin-top: 130px;
        margin-left: 00px;
    }
    .partners_logo4 {
        transform: scale(.2, .2);
        margin-top: 130px;
        margin-left: -30px;
    }
    .partners_logo5 {
        transform: scale(.15, .2);
        margin-top: 105px;
    }
}

@media only screen and (max-width: 800px) {
    .partners_logo1 {
        transform: scale(.1, .1);
        margin-top: 70px;
        margin-left: 20px;
    }
    .partners_logo2 {
        transform: scale(.06, .06);
        margin-top: -225px;
        margin-left: -10px;
    }
    .partners_logo3 {
        transform: scale(.2, .2);
        margin-top: 132px;
        margin-left: 20px;
    }
    .partners_logo4 {
        transform: scale(.1, .1);
        margin-top: 125px;
        margin-left: 10px;
    }
    .partners_logo5 {
        transform: scale(.1, .1);
        margin-top: 105px;
        margin-left: 20px;
    }
    .partners-container h2 {
        font-size: 30px;
        text-shadow: 1px 1px 2px #0F52BA;
    }
}