.ca_software-container {
    font-size: 44px;
    text-align: center;
    background-color: red;
    color: darkblue;
    text-shadow: 1px 1px 2px #0F52BA;
    padding: 50px;
    z-index: 1;
}

.ca_software-container h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
}

.slick-track {
    display: flex !important;
    height: 400px;
}

.slick-slide {
    height: inherit !important;
}

.software-img {
    margin: auto;
    height: 300px;
    width: 300px;
}

.logo1 p{
    color: black;
    font-size: 14px;
    position: absolute;
    top: 175px;
    padding-left: 430px;
}

.logo2 p{
    color: black;
    font-size: 14px;
    position: absolute;
    top: 175px;
    padding-left: 410px;
}
.logo3 p{
    color: black;
    font-size: 14px;
    position: absolute;
    top: 175px;
    padding-left: 390px;
}

.ca_software_logo1 {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    transform: scale(.1, .1);
    margin-top: -450px;
    justify-content: center;
}

.ca_software_logo2 {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    transform: scale(.225, .25);
    margin-top: 35px;
    justify-content: center;
}

.ca_software_logo3 {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    transform: scale(.3, .3);
    margin-top: 80px;
    justify-content: center;
}
.ca_software_logo4 {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    transform: scale(.4, .4);
    margin-top: 135px;
    justify-content: center;
}

.ca_software_logo5 {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    transform: scale(.13, .13);
    margin-top: 20px;
    justify-content: center;
}

.ca_software_logo6 {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    transform: scale(.15, .15);
    margin-top: -390px;
    justify-content: center;
}

.ca_software_logo7 {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    transform: scale(.08, .08);
    margin-top: -750px;
    justify-content: center;
}

.ca_software_logo8 {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    transform: scale(.13, .13);
    margin-top: -390px;
}

.ca_software-container {
    font-size: 44px;
    text-align: center;
    background-color: white;
    color: darkblue;
    height: auto;
}

.ca_software-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.ca_software-link {
    color: #09d3ac;
}

.ca_software_slides .slick-prev,
.ca_software_slides .slick-next {
    position: absolute;
    top: 50%;
    z-index: 1;
}

.ca_software_slides {
    position: relative;
}

.ca_software_slides .slick-prev,
.ca_software_slides .slick-next {
    position: absolute;
    top: 50%;
    z-index: 1;
}

.ca_software_slides .slick-prev {
    left: 2%;
}

.ca_software_slides .slick-next {
    right: 2%;
}

.slick-prev:before {
    color: red !important;
    background-color: #eee;
}

.slick-next:before {
    color: red !important;
    background-color: #eee;
}

@media only screen and (max-width: 1650px){
    .logo1 p{
        font-size: 14px;
        position: absolute;
        top: 175px;
        padding-left: 400px;
    }
    .logo2 p{
        color: black;
        font-size: 14px;
        position: absolute;
        top: 175px;
        padding-left: 360px;
    }
    .logo3 p{
        color: black;
        font-size: 14px;
        position: absolute;
        top: 175px;
        padding-left: 415px;
    }
}

@media only screen and (max-width: 1500px){
    .logo1 p{
        visibility: hidden;
    }
    .logo2 p{
        visibility: hidden;
    }
    .logo3 p{
        visibility: hidden;
    }
}


@media only screen and (max-width: 1400px) {
    .ca_software-container {
        font-size: 30px;
        text-align: center;
        padding: 50px;
    }
    .ca_software_logo1 {
        transform: scale(.065, .065);
        margin-top: -445px;
    }
    .ca_software_logo2 {
        transform: scale(.2, .2);
        margin-top: 35px;
    }
    .ca_software_logo3 {
        transform: scale(.275, .275);
        margin-top: 90px;
        margin-left: 0px;
    }
    .ca_software_logo4 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.4, .4);
        margin-top: 140px;
        margin-left: 100px;
    }
    .ca_software_logo5 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.13, .13);
        margin-top: 20px;
        margin-left: 0px;
    }
}

@media only screen and (max-width: 1000px) {
    .ca_software-container {
        font-size: 30px;
        text-align: center;
        padding: 50px;
    }
    .ca_software_logo1 {
        transform: scale(.04, .04);
        margin-top: -450px;
    }
    .ca_software_logo2 {
        transform: scale(.15, .15);
        margin-top: 35px;
        margin-left: -20px;
    }
    .ca_software_logo3 {
        transform: scale(.15, .15);
        margin-top: 80px;
        margin-left: 00px;
    }
    .ca_software_logo4 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.25, .25);
        margin-top: 135px;
        margin-left: 10px;
    }
    .ca_software_logo5 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.08, .08);
        margin-top: 20px;
        margin-left: 0px;
    }
    .ca_software_logo6 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.05, .05);
    }
    .ca_software_logo7 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.03, .03);
        margin-top: -765px;
    }
    .ca_software_logo8 {
        transform: scale(.06, .06);
    }
}

@media only screen and (max-width: 800px) {
    .ca_software-container h2 {
        font-size: 30px;
    }
    .ca_software_logo1 {
        transform: scale(.03, .03);
        margin-top: -445px;
        margin-left: 5px;
        justify-content: center;
    }
    .ca_software_logo2 {
        transform: scale(.1, .12);
        margin-top: 35px;
        margin-left: 5px;
    }
    .ca_software_logo3 {
        transform: scale(.1, .1);
        margin-top: 85px;
    }
    .ca_software_logo4 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.18, .18);
        margin-top: 140px;
        margin-left: 0px;
    }
    .ca_software_logo5 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.05, .05);
        margin-top: 22px;
        margin-left: 0px;
    }
    .ca_software_logo8 {
        transform: scale(.05, .05);
        margin-left: -30px;
    }
}