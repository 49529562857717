.footer-container {
    background-color: #242424;
    padding: 4rem 0 4rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.footer-links {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}

.footer-link-wrapper {
    display: flex;
}

.footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
}

.footer-link-items h2 {
    margin-bottom: 16px;
}

.footer-link-items>h2 {
    color: #fff;
}

.footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
}

.footer-email-form h2 {
    margin-bottom: 2rem;
}

.footer-logo {
    width: 100%;
    flex: 1;
}

.website-rights {
    text-align: center;
    flex: 1;
}

/* Social Icons */

.social-icon-link {
    color: #fff;
    font-size: 24px;
}

.social-media {
    max-width: 1000px;
    width: 100%;
}

.social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1000px;
}

.social-icons {
    justify-content: space-between;
    align-items: center;
    width: 240px;
    padding-left: 5px;
    padding-bottom: 10px;
}

.website-rights {
    color: #fff;
    margin-bottom: 16px;
}

@media screen and (max-width: 1185px) {
    .footer-logo {
        height: 85px;
        width: 100%;
        flex: 1.25;
    }
}

@media screen and (max-width: 820px) {
    .footer-links {
        padding-top: 2rem;
    }
    .btn {
        width: 100%;
    }
    .footer-link-wrapper {
        flex-direction: column;
    }
    .footer-logo a {
        position: absolute;
        max-width: 130px;
        width: 100%;
        text-align: center;
        transform: translate(5%, 50%);
        margin-left: 0px;
    }
    .footer-logo a img {
        max-width: 130px;
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .footer-links {
        padding-top: 2rem;
    }
    .btn {
        width: 100%;
    }
    .footer-link-items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 16px;
        text-align: left;
        width: 65px;
        box-sizing: border-box;
    }
    .website-rights {
        font-size: 12px;
        flex: 1;
    }
    .social-icons {
        flex: 1;
        text-align: center;
    }
    .social-icon-link i{
        margin-right: 0%;
    }
}