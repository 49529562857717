.projectmanagement-header {
    background-image: url("../projectmanagement.jpg");
    background-color: #FAF9F6;
    background-size: cover;
    height: 300px;
}

.projectmanagement-wrapper {
    padding: 50px;
    background-color: white;
    display: block;
}

.projectmanagement-container {
    padding: 10px;
    width: 80%;
    margin-left: 10%;
}

.projectmanagement-container h4 {
    font-size: 45px;
    text-align: left;
    color: #696969;
    padding-bottom: 10px;
}

.projectmanagement-container p {
    font-size: 20px;
    padding-bottom: 10px;
}

.projectmanagement-container2 {
    padding: 10px;
    width: 80%;
    margin-left: 10%;
}

.projectmanagement-container2 h5 {
    font-size: 30px;
    text-align: left;
    padding-bottom: 5px;
    color: #696969;
}

.projectmanagement-container2 p {
    font-size: 20px;
}