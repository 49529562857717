.cloud-header {
    background-image: url("../cloud.jpg");
    background-color: #FAF9F6;
    background-size: cover;
    height: 300px;
}

.cloud-wrapper {
    padding: 50px;
    background-color: white;
    display: block;
}

.cloud-container {
    padding: 10px;
    width: 80%;
    margin-left: 10%;
}

.cloud-container h4 {
    font-size: 45px;
    text-align: left;
    color: #696969;
    padding-bottom: 10px;
}

.cloud-container p {
    font-size: 20px;
}

.cloud-container2 {
    padding: 10px;
    width: 80%;
    margin-left: 10%;
}

.cloud-container2 h5 {
    font-size: 30px;
    text-align: left;
    padding-bottom: 5px;
    color: #696969;
}

.cloud-container2 p {
    font-size: 20px;
}

.cloud-container3 {
    padding: 10px;
    width: 80%;
    margin-left: 10%;
}

.cloud-container3 h5 {
    font-size: 30px;
    text-align: left;
    color: #696969;
}

.cloud-container3 ul,
li {
    font-size: 20px;
    padding-top: 5px;
}

.aws-container {
    padding: 10px;
    width: 20%;
    margin-left: 10%;
    margin-top: 20px;
    width: 75%;
    margin: auto;
}

.aws-text {
    padding: 10px;
    width: 20%;
    margin-left: 10%;
    margin-top: 20px;
    width: 75%;
    margin: auto;
}

.awsWrapper {
    display: grid;
    grid-template-columns: 50% 50% 50%;
    height: auto;
    text-align: left;
    margin-right:auto;
    margin-left:auto;
    width: 80%;
    font-size: 20px;
}

.aws-container img {
    width: 235px;
    height: 100px;
    position: center;
    margin-left:auto;
    margin-right:auto;
}

@media only screen and (max-width: 800px) {
    .aws-container img {
        width: 160px;
        height: 55px;
    }
    .awsWrapper{
        width: 100%;
    }
    .aws-text{
        font-size: 16px;
        width: 90%;
    }
}