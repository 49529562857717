.news-header {
    background-image: linear-gradient(to bottom, rgba(38, 106, 143, 0.5), rgba(90, 45, 129, .9)), url("../roseGarden.jpg");
    background-color: #FAF9F6;
    background-size: cover;
    padding: 60px 60px;
    opacity: 1;
    height: 300px;
    color: white;
}

.news-header h3 {
    padding-top: 100px;
    text-align: left;
    font-size: 28px;
    font-family: 'Roboto', sans-serif;
    margin: auto;
    padding-left: 20%;
}

.news-header p {
    text-align: left;
    font-size: 20px;
    padding-top: 12px;
    font-family: 'Roboto', sans-serif;
    margin: auto;
    padding-left: 20%;
}

.news-wrapper {
    padding: 20px;
    width: 65%;
    position: center;
    margin: auto;
    display:flex;
    flex-direction: row;
}

.news-text-container {
    padding: 20px;
    -moz-box-shadow: 0px 1px 5px 0px #676767;
    -webkit-box-shadow: 0px 1px 5px 0px #676767;
    box-shadow: 0px 1px 5px 0px #676767;
}

.news-text-container h3 {
    font-size: 20px;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    text-align: center;
}

.news-text-container p {
    font-size: 18px;
    margin: 0;
    padding: 15px;
    font-family: 'Roboto', sans-serif;
    text-align: left;
}

.news-image-container{
    margin: auto;
    position: right;
    text-align: right;
    
}

.news-image-container img{
    height:120px;
    width:120px;
}


@media only screen and (max-width: 800px) {
    .news-wrapper {
        width: 100%;
    }

    .news-text-container {
        padding: 15px;

    }

}

@media only screen and (max-width: 450px) {
    .news-text-container {
        padding: 2px;

    }
}