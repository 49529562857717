.wrapper {
    padding: 50px;
    background-color: white;
    display: block;
    height: 500px;
}

h3 {
    font-size: 24px;
    text-align: left;
    color: black;
    padding-bottom: 10px;
    padding-top: 20px;
    margin-left: 0%;
    padding-left: auto;
}

p {
    text-align: left;
    font-size: 20px;
    padding-top: 12px;
    padding-left: auto;
    font-family: 'Roboto', sans-serif;
    margin: auto;
}