.slick-track {
    display: flex !important;
    height: 350px;
}

.slick-slide {
    height: inherit !important;
}

.clients_logo1 {
    margin-top: -400px;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    transform: scale(.11, .11);
}

.clients_logo2 {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    transform: scale(.36, .36);
    margin-left: 100px;
}

.clients_logo3 {
    margin-top: -570px;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    transform: scale(.09, .09);
}

.clients_logo4 {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    transform: scale(.34, .34);
    padding-left: 150px;
}

.clients_logo5 {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    transform: scale(.22, .275);
    margin-top: 90px;
    margin-left: 0px;
}

.clients_logo6 {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    transform: scale(.5, .6);
    margin-top: 150px;
    margin-left: 50px;
}

.clients-container {
    font-size: 44px;
    text-align: center;
    color: darkblue;
    text-shadow: 1px 1px 2px #0F52BA;
    padding: 50px;
    z-index: 1;
}

.clients-container h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    text-shadow: 1px 1px 2px #0F52BA;
}

.clients-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.clients-link {
    color: #09d3ac;
}

.clients_slides .slick-prev,
.clients_slides .slick-next {
    position: absolute;
    top: 50%;
    z-index: 1;
}

.clients_slides {
    position: relative;
}

.clients_slides .slick-prev,
.clients_slides .slick-next {
    position: absolute;
    top: 50%;
    z-index: 1;
}

.clients_slides .slick-prev {
    left: 2%;
}

.clients_slides .slick-next {
    right: 2%;
}

.slick-prev:before {
    color: red !important;
    background-color: #eee;
}

.slick-next:before {
    color: red !important;
    background-color: #eee;
}

@media only screen and (max-width: 1400px) {
    .clients-container {
        font-size: 30px;
        text-align: center;
        padding: 50px;
    }
    .clients_logo1 {
        margin-top: -400px;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.1, .1);
        margin-left: 20px;
    }
    .clients_logo2 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.33, .33);
        margin-left: 25px;
    }
    .clients_logo3 {
        margin-top: -570px;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.085, .085);
        margin-left: -35px;
    }
    .clients_logo4 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.3, .3);
        padding-left: 10px;
    }
    .clients_logo5 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.2, .27);
        margin-top: 90px;
        margin-left: -100px;
    }
    .clients_logo6 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.45, .5);
        margin-top: 150px;
        margin-left: 0px;
    }
}

@media only screen and (max-width: 1000px) {
    .clients_logo1 {
        margin-top: -400px;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.08, .08);
        margin-left: 100px;
    }
    .clients_logo2 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.29, .29);
        margin-left: 40px;
    }
    .clients_logo3 {
        margin-top: -570px;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.07, .07);
        margin-left: -10px;
    }
    .clients_logo4 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.27, .27);
        padding-left: 10px;
    }
    .clients_logo5 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.18, .23);
        margin-top: 90px;
        margin-left: -0px;
    }
    .clients_logo6 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.38, .4);
        margin-top: 150px;
        margin-left: 100px;
    }
}

@media only screen and (max-width: 800px) {
    .clients_logo1 {
        margin-top: -400px;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.04, .04);
        margin-left: 100px;
    }
    .clients_logo2 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.15, .15);
        margin-left: 40px;
    }
    .clients_logo3 {
        margin-top: -570px;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.035, .035);
        margin-left: -10px;
    }
    .clients_logo4 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.135, .135);
        padding-left: 10px;
    }
    .clients_logo5 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.09, .115);
        margin-top: 90px;
        margin-left: -0px;
    }
    .clients_logo6 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.19, .2);
        margin-top: 150px;
        margin-left: 100px;
    }
    .clients-container h2 {
        font-size: 30px;
        color: #000042;
    }
}

@media only screen and (max-width: 600px) {
    .clients_logo1 {
        margin-top: -400px;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.04, .04);
        margin-left: 100px;
    }
    .clients_logo2 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.14, .14);
        margin-left: 77px;
    }
    .clients_logo3 {
        margin-top: -570px;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.035, .035);
        margin-left: 50px;
    }
    .clients_logo4 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.135, .135);
        padding-left: 90px;
        margin-top: -5px;
    }
    .clients_logo5 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.09, .115);
        margin-top: 90px;
        margin-left: -0px;
    }
    .clients_logo6 {
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        transform: scale(.19, .2);
        margin-top: 150px;
        margin-left: 70px;
    }
}