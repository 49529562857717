video {
    object-fit: cover;
    width: 100%;
    height: 50%;
    position: absolute;
    z-index: -1;
}

.hero-container {
    height: 450px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    object-fit: contain;
    z-index: -1;
}

.hero-container>h3 {
    color: #fff;
    margin-left: 5%;
    font-size: 48px;
    margin-top: 5px;
    padding: 40px;
    font-family: 'Roboto', sans-serif;
    text-shadow: 5px 5px #000000;
}

@media only screen and (max-width: 800px) {
    .hero-container {
        height: 325px;
    }
    .hero-container>h3 {
        font-size: 40px;
        padding: 20px;

    }
}