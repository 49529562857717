.hiddenbutton {
    font-size: 200px;
}

.contactus-title {
    text-align: center;
    background-color: white;
    padding-top: 20px;
    padding-bottom: 30px;
    font-size: 40px;
    text-shadow: 1px 1px 2px white;
    font-family: 'Roboto', sans-serif;
}

.contactus-wrapper{
    background-color:white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactus-container {
    padding:50px;
    padding-top: 25px;
    height: 600px;
    width: 950%;
    background-color: white;
    z-index: 1;
}

.contactus-form {
    padding: 0px;
    text-align: left;
    margin: auto;
    color: black;
    width: 45%;
}

input:focus{
    background-color: lightyellow;
}

textarea:focus{
    background-color: lightyellow;
}

input[type="text"],
input[type="email"],
textarea {
    padding: 10px;
    color: rgb(0, 0, 0);
    display: flex;
    height: 30px;
    margin-top: 5px;
}

label[type="message"],
textarea {
    padding: 10px;
    min-height: 150px;
    color: black;
}

input[type="text"],
textarea {
    width: 100%;
}

input[type="email"],
textarea {
    width: 100%;
}

input[type="submit"] {
    font-size: 16px;
    padding: 10px 30px 10px 30px;
    border-radius: 5px;
    margin-top: 20px;
    width: 100%;
    background-color: #212F3C;
    color: white;
}

@media screen and (max-width: 950px){
    .contactus-form {
        width: 100%;
    }
}