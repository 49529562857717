.projects-header {
    background-image: linear-gradient(to bottom, rgba(38, 106, 143, 0.5), rgba(90, 45, 129, .9)), url("../cityView.jpg");
    background-color: #FAF9F6;
    background-size: cover;
    opacity: 1;
    height: 300px;
    color: white;
    width: 100%;
    padding: 0px 40px;
}

.projects-wrapper {
    padding: 20px;
    width: 100%;
    position: center;
    margin: auto;
    max-width: 1200px;
}

.projects-wrapper h4 {
    font-size: 34px;
    text-align: center;
    background-color: #fcf3cf;
    padding: 10px;
    font-family: 'Roboto', sans-serif;
}

.projects-wrapper p {
    font-size: 20px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
}

.projects-text-container {
    padding: 50px;
    width: 100%;
}

.projects-text-container h3 {
    font-size: 24px;
    text-align: left;
    color: #21618c;
    padding-bottom: 10px;
}

.projects-text-container p {
    font-size: 18px;
    text-align: left;
    margin: 0;
    padding-bottom: 15px;
    padding-top: 10px;
}

.divider {
    padding-top: 25px;
}