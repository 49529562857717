*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.service-header {
    padding-top: 100px;
    height: 300px;
    background-image: linear-gradient(to bottom, rgba(38, 106, 143, 0.5), rgba(90, 45, 129, .9)), url("../collaboration.jpg");
    width: 100%;
    background-size: cover;
}

.service-header h2 {
    color: white;
    font-size: 55px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 27px;
    font-family: 'Roboto', sans-serif;
}

.service-header h5 {
    font-size: 20px;
    text-align: center;
    color: white;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 100px;
    font-family: 'Roboto', sans-serif;
}

.superservice-wrapper {
    background-color: white;
}

.services-wrapper {
    color: black;
    padding: 20px;
    background-color: white;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.services-container1 {
    background-color: none;
    padding: 20px;
    display: flex;
}

.service-1-1 {
    text-align: center;
}

.service-1 {
    border-style: solid;
    border-color: #1d1135;
    border-width: 2.5px;
    padding: 10px;
    margin: 10px;
    flex-basis: 100%;
}

.service-1 h3 {
    color: black;
    font-size: 22px;
    text-align: center;
    padding: 20px;
    padding-bottom: 5px;
}

.service-1 p {
    padding: 20px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.service-2 {
    border-style: solid;
    border-color: #0c164f;
    padding: 10px;
    margin: 10px;
    flex-basis: 100%;
}

.service-2 h3 {
    color: black;
    font-size: 22px;
    text-align: center;
    padding: 20px;
    padding-bottom: 5px;
}

.service-2 p {
    padding: 20px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.service-3 {
    border-style: solid;
    border-color: #ba1e68;
    padding: 10px;
    margin: 10px;
    flex-basis: 100%;
}

.service-3 h3 {
    color: black;
    font-size: 22px;
    text-align: center;
    padding: 20px;
    padding-bottom: 5px;
}

.service-3 p {
    padding: 20px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.services-container2 {
    background-color: transparent;
    padding: 20px;
    display: flex;
}

.service-4 {
    border-style: solid;
    border-color: #5643fd;
    padding: 10px;
    margin: 10px;
    flex-basis: 100%;
}

.service-4 h3 {
    color: black;
    font-size: 22px;
    text-align: center;
    padding: 20px;
    padding-bottom: 5px;
}

.service-4 p {
    padding: 20px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.service-5 {
    border-style: solid;
    border-color: #7649fe;
    padding: 10px;
    margin: 10px;
    flex-basis: 100%;
}

.service-5 h3 {
    color: black;
    font-size: 22px;
    text-align: center;
    padding: 20px;
    padding-bottom: 5px;
}

.service-5 p {
    padding: 20px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.service-6 {
    border-style: solid;
    border-color: #04056d8e;
    padding: 10px;
    margin: 10px;
    flex-basis: 100%;
}

.service-6 h3 {
    color: black;
    font-size: 22px;
    text-align: center;
    padding: 20px;
    padding-bottom: 5px;
}

.service-6 p {
    padding: 20px;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

@media screen and (max-width: 1200px) {
    .services-container1 {
        background-color: none;
        padding: 20px;
        display: inline;
    }
    .services-container2 {
        background-color: none;
        padding: 20px;
        display: inline;
    }
}