.terms-container {
    padding: 100px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.terms-container h3 {
    font-size: 30px;
    margin: 0;
    color: #2f4f4f;
    text-align: left;
    padding: 0px;
}

.terms-container h2 {
    font-size: 20px;
}

.terms-container h4 {
    font-size: 22px;
    padding-top: 20px;
    padding-bottom: 10px;
}

.terms-container p {
    padding: 0;
    margin-left: 0;
    font-size: 18px;
}