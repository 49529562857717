.careers-wrapper {
    display: grid;
    grid-template-columns: 50% 50% 50%;
    height: auto;
}

.careers-container {
    background-color: #b6d0e2;
    padding: 100px;
}

.careers-container11 {
    width: 75%;
    margin: auto;
}

.careers-container2 {
    background-color: white;
    padding: 20px;
    padding-bottom: 50px;
}

.careers-container22 {
    width: 70%;
    margin: auto;
}

.careers-title {
    font-size: 24px;
    text-align: left;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

.careers-text p {
    font-size: 18px;
    color: black;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    padding-bottom: 12px;
}

.button_positions {
    background-color: #008080;
    border: none;
    color: white;
    padding: 14px 24px;
    text-align: center;
    font-size: 14px;
    margin: 4px 2px;
    opacity: 0.6;
    transition: 0.3s;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    border-radius: 20px;
    font-family: 'Roboto', sans-serif;
}

.button_positions:hover {
    opacity: 1;
}

.careers-image {
    background-image: url("../sac_river.jpg");
    background-size: cover;
}

.careers-quote h3 {
    text-align: center;
    margin: 0px;
    font-size: 24px;
    padding: 25px;
}

.careers-team {
    text-align: center;
    font-size: 30px;
    padding-bottom: 25px;
}

.careers-team-text {
    font-size: 20px;
}

.careers-team-text p {
    font-family: 'Roboto', sans-serif;
}

.careers-positions {
    margin: 0px;
    border-left: 5px solid;
    border-color: gray;
    padding-left: 20px;
    font-size: 30px;
    text-align: left;
    padding-top: 20px;
    padding-left: 40px;
}

.careers-positions ul {
    padding-top: 20px;
    list-style-type: none;
    font-size: 18px;
    padding-left: 20px;
}

.careers-positions li {
    font-family: 'Roboto', sans-serif;
}

@media only screen and (max-width: 1200px) {
    .careers-wrapper {
        display: inline;
        grid-template-columns: 100% 100% 100%;
    }
    .careers-container {
        background-color: #b6d0e2;
        padding: 50px;
    }
    .careers-positions {
        margin: 0px;
        padding-left: 20px;
        font-size: 30px;
        padding-left: 140px;
        padding-top: 20px;
        padding-bottom: 35px;
        border-color: white;
    }
}