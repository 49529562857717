.conference-wrapper {
    padding: 20px;
    display: block;
    text-align: center;
}

.demo {
    position: relative !important;
    z-index: 1 !important;
    max-width: 1000px;
    text-align: center;
}

.conference-container {
    width: 80%;
    margin-left: 10%;
}

.conference-container h4 {
    font-size: 45px;
    padding-bottom: 20px;
}

.conference-container p {
    font-size: 20px;
    padding-bottom: 15px;
}

.contact-form {
    width: 100%;
    height: auto;
    margin: auto;
    font-size: 45px;
    padding-top: 15px;
    padding-bottom: 5px;
}

.contact-form p {
    text-align: center;
    font-size: large;
}

.contact-form form {
    max-width: 500px;
    padding: 15px;
    text-align: left;
    font-size: 18px;
}

.contact-form input {
    margin: 10px 0px;
    padding-top: 10px;
}

.cards-list {
    padding: 25px;
    display: flex;
    justify-content: center;
}